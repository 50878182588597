import React from 'react';
import styled from 'styled-components';
import { Heading1, Paragraph } from '../../Typography';

const TextBox = ({ heading, body }) => {
  return (
    <Container>
      <Heading1 fontSize='37px'>{heading}</Heading1>
      <Paragraph fontSize='18px'>{body}</Paragraph>
    </Container>
  );
};

export default TextBox;

const Container = styled.div`
  background: #ebecf7;
  padding: 40px;
  margin-top: 60px;
  height: fit-content;
  max-width: 500px;
  @media (max-width: 992px) {
    max-width: 80%;
    margin-top: 0px;
    background: none;
  }
  @media (max-width: 576px) {
    max-width: 100%;
    margin-top: 0px;
    padding: 16px;
    background: none;
  }
`;
