import React, { useState } from 'react';
import TextBox from '../PaymentPlan/Validate/TextBox';
import Card from '../Card';
import { Heading1 } from '../Typography';
import styled from 'styled-components';
import { Input } from '../Forms';
import Button from '../Button';
import Link from '../Link';
import Notification from '../Notification';
import { navigate } from 'gatsby';
import fetch from '../../utils/fetch';
import Flexbox from '../Flexbox';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

const fields = [
  { id: 'jacobs-ref', name: 'jacobs_ref', label: 'Jacobs reference' },
  { id: 'client-ref', name: 'client_ref', label: 'Client reference' },
  { id: 'postcode', name: 'postcode', label: 'Postcode' },
];

function WebServiceLogin({ title, body, redirectOnLogin }) {
  const [error, setError] = useState();

  const [formData, setFormData] = useState({});

  function handleFieldChange(e) {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const submit = async (e) => {
    e.preventDefault();
    try {
      await fetch.get('/api/csrf-cookie');

      const response = await fetch.post('/api/login', formData);

      const isHMCTS = response.data.data.hmcts;

      const branchID = response.data.data.branch;

      setError(undefined);
      if (window) {
        return navigate(redirectOnLogin, {
          state: {
            authenticated: true,
            userDetails: formData,
            isHMCTS,
            branchID,
          },
          replace: true,
        });
      }
    } catch (error) {
      //Handles no responses and 500 error, otherwise it will fallback to credentials not recognised.
      if (!error.response) {
        return setError(
          'Unable to connect with server. Please try again, if this continues please contact us.'
        );
      }
      if (error.response.status === 500) {
        return setError(
          '500: Server error. Please try again, if this continues please contact us.'
        );
      }
      return setError('Credentials not recognised.');
    }
  };

  return (
    <>
      <TextBox heading={title} body={body} />
      <Card>
        <Container>
          <Heading1 textAlign='center'>Enter Reference Numbers</Heading1>
          <form onSubmit={submit}>
            {fields.map((field) => (
              <Input
                key={field.id}
                id={field.id}
                type='text'
                onChange={handleFieldChange}
                required
                name={field.name}
                label={field.label}
              />
            ))}
            <Button type='submit' mt={40} width='100%' height={40}>
              Validate
            </Button>
            {error && (
              <Notification padding='8px' marginTop='20px' textAlign='center'>
                {error}
              </Notification>
            )}
            <Flexbox justifyContent='center' mt='16px'>
              <Link href='/contact' fontSize={16}>
                Having trouble validating your account?
              </Link>
            </Flexbox>
            <Flexbox justifyContent='center' mt='16px'>
              <Button
                as='a'
                href='/services'
                width={240}
                height={40}
                icon={faArrowCircleLeft}
              >
                Return to Services
              </Button>
            </Flexbox>
          </form>
        </Container>
      </Card>
    </>
  );
}
export default WebServiceLogin;

const Container = styled.div`
  padding: 40px;
  @media (max-width: 576px) {
    padding: 24px 16px;
  }
`;
