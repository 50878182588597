import React from 'react';
import HeroSection from '../../components/HeroSection';
import Section from '../../components/Section';
import styled from 'styled-components';
import WebServiceLogin from '../../components/WebServices/Login';
import { navigate } from 'gatsby';

const Login = ({ location }) => {
  React.useEffect(() => {
    if (!location.state) {
      navigate('/services');
    }
  }, [location.state]);

  return (
    <>
      <HeroSection />
      <Section backgroundColor='grey' paddingTop='74px' paddingBottom='80px'>
        <Container>
          <WebServiceLogin {...location.state} />
        </Container>
      </Section>
    </>
  );
};

export default Login;

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: 992px) {
    display: block;
  }
`;
